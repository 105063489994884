import { AnyAction } from 'redux';

import * as constants from 'redux/constants/blocks.constants';
import { ITableUpdate } from 'types/table.types';
import { IBlocksFormData } from 'views/BlocksView/components/BlocksForm/types';

export const onDelete = (cementeryId: string, id: string): AnyAction => ({
  type: constants.BLOCKS_ON_DELETE_REQUESTED,
  cementeryId,
  id,
});

export const onGetAll = (cementeryId: string, data: ITableUpdate): AnyAction => ({
  type: constants.BLOCKS_ON_GET_ALL_REQUESTED,
  cementeryId,
  ...data,
});

export const onGetOne = (cementeryId: string, id: string): AnyAction => ({
  type: constants.BLOCKS_ON_GET_ONE_REQUESTED,
  cementeryId,
  id,
});

export const onSave = (cementeryId: string, formData: IBlocksFormData, id?: string): AnyAction => ({
  type: constants.BLOCKS_ON_SAVE_REQUESTED,
  cementeryId,
  formData,
  id,
});
