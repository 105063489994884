import { all } from 'redux-saga/effects';

import { watchAuth } from 'redux/sagas/auth.sagas';
import { watchBlocks } from 'redux/sagas/blocks.sagas';
import { watchCementeries } from 'redux/sagas/cementeries.sagas';
import { watchDocuments } from 'redux/sagas/documents.sagas';
import { watchDocumentsTypes } from 'redux/sagas/documentsTypes.sagas';
import { watchGraves } from 'redux/sagas/graves.sagas';
import { watchPermissions } from 'redux/sagas/permissions.sagas';
import { watchPersons } from 'redux/sagas/persons.sagas';
import { watchRoles } from 'redux/sagas/roles.sagas';
import { watchUsers } from 'redux/sagas/users.sagas';

export default function* rootSaga(): Generator {
  yield all([
    watchAuth(),
    watchBlocks(),
    watchCementeries(),
    watchDocuments(),
    watchDocumentsTypes(),
    watchGraves(),
    watchPermissions(),
    watchPersons(),
    watchRoles(),
    watchUsers(),
  ]);
}
