import { Middleware } from 'redux';

import * as authConstants from 'redux/constants/auth.constants';
import * as blocksConstants from 'redux/constants/blocks.constants';
import * as cementeriesConstants from 'redux/constants/cementeries.constants';
import * as documentsConstants from 'redux/constants/documents.constants';
import * as documentsTypesConstants from 'redux/constants/documentsTypes.constants';
import * as gravesConstants from 'redux/constants/graves.constants';
import * as permissionsConstants from 'redux/constants/permissions.constants';
import * as personsConstants from 'redux/constants/persons.constants';
import * as rolesConstants from 'redux/constants/roles.constants';
import * as usersConstants from 'redux/constants/users.constants';
import { history } from 'helpers/history';

const redirectMiddleware: Middleware = () => (next) => (action) => {
  const { data, error, pathname, type } = action;
  switch (type) {
    case authConstants.AUTH_ON_LOGIN_SUCCEEDED:
      setTimeout(() => history.push(pathname || '/'), 0);
      break;
    case authConstants.AUTH_ON_LOGOUT_SUCCEEDED:
    case authConstants.AUTH_ON_INITIALIZE_FAILED:
      setTimeout(() => history.push('/login'), 0);
      break;
    case blocksConstants.BLOCKS_ON_SAVE_SUCCEEDED:
      history.push(`/cementeries/${data.cementeryId}/blocks`);
      break;
    case cementeriesConstants.CEMENTERIES_ON_SAVE_SUCCEEDED:
      history.push('/cementeries');
      break;
    case documentsConstants.DOCUMENTS_ON_DELETE_SUCCEEDED:
    case documentsConstants.DOCUMENTS_ON_SAVE_SUCCEEDED:
      history.push('/persons');
      break;
    case documentsTypesConstants.DOCUMENTS_TYPES_ON_SAVE_SUCCEEDED:
      history.push('/documents/types/list');
      break;
    case gravesConstants.GRAVES_ON_DELETE_SUCCEEDED:
    case gravesConstants.GRAVES_ON_SAVE_SUCCEEDED:
      history.push(`/cementeries/${data.cementeryId}/blocks/${data.blockId}/show`);
      break;
    case permissionsConstants.PERMISSIONS_ON_SAVE_SUCCEEDED:
      history.push('/users/permissions/list');
      break;
    case personsConstants.PERSONS_ON_SAVE_SUCCEEDED:
      history.push('/persons');
      break;
    case rolesConstants.ROLES_ON_SAVE_SUCCEEDED:
      history.push('/users/roles/list');
      break;
    case usersConstants.USERS_ON_SAVE_SUCCEEDED:
      history.push('/users/list');
      break;
    case authConstants.AUTH_ON_LOGIN_FAILED:
      break;
    default:
      if (error?.response?.status === 401) setTimeout(() => history.push('/'), 0);
      break;
  }
  return next(action);
};

export default redirectMiddleware;
