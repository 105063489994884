import { combineReducers } from 'redux';

import appReducer from 'redux/reducers/app.reducer';
import authReducer from 'redux/reducers/auth.reducer';
import blocksReducer from 'redux/reducers/blocks.reducer';
import cementeriesReducer from 'redux/reducers/cementeries.reducer';
import documentsReducer from 'redux/reducers/documents.reducer';
import documentsTypesReducer from 'redux/reducers/documentsTypes.reducer';
import gravesReducer from 'redux/reducers/graves.reducer';
import permissionsReducer from 'redux/reducers/permissions.reducer';
import personsReducer from 'redux/reducers/persons.reducer';
import rolesReducer from 'redux/reducers/roles.reducer';
import usersReducer from 'redux/reducers/users.reducer';

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  blocks: blocksReducer,
  cementeries: cementeriesReducer,
  documents: documentsReducer,
  documentsTypes: documentsTypesReducer,
  graves: gravesReducer,
  permissions: permissionsReducer,
  persons: personsReducer,
  roles: rolesReducer,
  users: usersReducer,
});

export default rootReducer;
