export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const FILE_TYPES = {
  pdf: 'application/pdf',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  svg: 'image/svg+xml',
};

export const FILE_TYPES_ARRAY = Object.values(FILE_TYPES);

export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!_@#$%^&+=*()`:;"'/?,<>.\\|[\]{}~-])/;
export const PASSWORD_MINIMUM_LENGTH = 8;

export const PHONE_NUMBER_REGEX = /^(54)?[1-9]\d{9}$/i;
