import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import * as constants from 'redux/constants/blocks.constants';
import { getAll, getOne, save, softDelete } from 'services/blocks.services';

export function* blocksDelete(action: AnyAction): Generator {
  try {
    const { cementeryId, id }: any = action;
    const { data }: any = yield call(softDelete, cementeryId, id);
    yield put({
      type: constants.BLOCKS_ON_DELETE_SUCCEEDED,
      data: { ...data, cementeryId, id },
    });
  } catch (error) {
    yield put({ type: constants.BLOCKS_ON_DELETE_FAILED, error });
  }
}

export function* blocksGetAll(action: AnyAction): Generator {
  try {
    const { cementeryId, limit, page, search, sortKey, sortType } = action;
    const { data }: any = yield call(getAll, cementeryId, limit, page, search, sortKey, sortType);
    yield put({ type: constants.BLOCKS_ON_GET_ALL_SUCCEEDED, data: { ...data, cementeryId } });
  } catch (error) {
    yield put({ type: constants.BLOCKS_ON_GET_ALL_FAILED, error });
  }
}

export function* blocksGetOne(action: AnyAction): Generator {
  try {
    const { cementeryId, id }: any = action;
    if (id) {
      const { data }: any = yield call(getOne, cementeryId, id);
      yield put({ type: constants.BLOCKS_ON_GET_ONE_SUCCEEDED, data: { ...data, cementeryId } });
    }
  } catch (error) {
    yield put({ type: constants.BLOCKS_ON_GET_ONE_FAILED, error });
  }
}

export function* blocksSave(action: AnyAction): Generator {
  try {
    const { cementeryId, formData, id } = action;
    const { data }: any = yield call(save, cementeryId, formData, id);
    yield put({ type: constants.BLOCKS_ON_SAVE_SUCCEEDED, data: { ...data, cementeryId } });
  } catch (error) {
    yield put({ type: constants.BLOCKS_ON_SAVE_FAILED, error });
  }
}

export function* blocksRefresh(): Generator {
  const cementeryId: any = yield select((state) => state.blocks.cementeryId);
  const table: any = yield select((state) => state.blocks.table);
  yield put({ type: constants.BLOCKS_ON_GET_ALL_REQUESTED, cementeryId, ...table });
}

export function* watchBlocks(): Generator {
  yield all([
    takeLatest(constants.BLOCKS_ON_DELETE_REQUESTED, blocksDelete),
    takeLatest(constants.BLOCKS_ON_GET_ALL_REQUESTED, blocksGetAll),
    takeLatest(constants.BLOCKS_ON_GET_ONE_REQUESTED, blocksGetOne),
    takeLatest(constants.BLOCKS_ON_SAVE_REQUESTED, blocksSave),
    takeLatest(
      [constants.BLOCKS_ON_DELETE_SUCCEEDED, constants.BLOCKS_ON_SAVE_SUCCEEDED],
      blocksRefresh,
    ),
  ]);
}
