export const COLORS = {
  black: '#000000',
  blackEbony: '#0B141E',
  blueBayoux: '#475E75',
  blueCornflower: '#7367F0',
  blueCream: '#AFDBF5',
  blueDodger: '#1B90FF',
  blueEndeavour: '#0056B3',
  blueNavy: '#1D2E52',
  blueRibbon: '#0D6EFD',
  blueViolet: '#574EB8',
  boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.05)',
  gray: '#727272',
  grayAlabaster: '#F8F8F8',
  grayAlto: '#E0E0E0',
  grayCadetBlue: '#A9B4BE',
  grayCloudBurst: '#223548',
  grayEmperor: '#515151',
  grayIron: '#D5DADD',
  grayLynch: '#5B738B',
  grayManatee: '#8F88A4',
  grayPorcelain: '#F5F6F7',
  grayScorpion: '#565656',
  greenChristi: '#7DB00E',
  greenCoconutCream: '#F5FAE5',
  greenLaPalma: '#188918',
  modalBackground: 'rgba(0, 0, 0, 0.5)',
  orangeOutrageous: '#FF532B',
  orangePomegranate: '#F13E14',
  red: '#FF0000',
  redBittersweet: '#FF6961',
  redLavenderBlush: '#FFEAF4',
  redMilano: '#BB0A0A',
  redMonza: '#D20A0A',
  redPersian: '#D32F2F',
  yellowMilkPunch: '#FFF8D6',
  yellowRoseofSharon: '#C35500',
  yellowGold: '#BfA96C',
  white: '#FFFFFF',
};
