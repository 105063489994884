import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  AssignmentOutlined,
  FindInPageOutlined,
  Groups2Outlined,
  HomeOutlined,
  PersonOutlined,
  SecurityOutlined,
  NoteAltOutlined,
  WarehouseOutlined,
  PersonSearchOutlined,
} from '@mui/icons-material';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';

import { isMenuOpenedSelector } from 'redux/selectors/app.selector';
import { onToggleMenu } from 'redux/actions/app.actions';
import { userSelector } from 'redux/selectors/auth.selector';
import { isDirectorioRole, isRabinatoRole, isSuperAdminRole } from 'helpers/roles';

import Sidebar from 'components/main/Sidebar';

import 'components/main/Sidebar/i18n';

export interface ISidebarContainerProps {
  isMobile: boolean;
}

const SidebarContainer: FunctionComponent<ISidebarContainerProps> = (
  props: ISidebarContainerProps,
) => {
  const { isMobile } = props;
  const dispatch = useDispatch();
  const isMenuOpened = useTypedSelector(isMenuOpenedSelector);
  const user = useTypedSelector(userSelector);
  const { pathname } = useLocation();

  const onCloseMenu = () => dispatch(onToggleMenu(false));

  useEffect(() => {
    if (isMobile && isMenuOpened) setTimeout(onCloseMenu, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const items = [
    {
      icon: HomeOutlined,
      key: 'dashboard',
      label: i18next.t<string>('SIDEBAR:DASHBOARD'),
      to: '/dashboard',
    },
    ...(isSuperAdminRole(user) || isDirectorioRole(user)
      ? [
          {
            icon: WarehouseOutlined,
            key: 'cementeries',
            label: i18next.t<string>('SIDEBAR:CEMENTERIES'),
            to: '/cementeries',
          },
        ]
      : []),
    ...(isSuperAdminRole(user) || isRabinatoRole(user)
      ? [
          {
            icon: AssignmentOutlined,
            items: [
              {
                icon: NoteAltOutlined,
                key: 'documents-create',
                label: i18next.t<string>('SIDEBAR:DOCUMENTS_CREATE'),
                to: '/documents/create',
              },
              {
                icon: FindInPageOutlined,
                key: 'documents-types',
                label: i18next.t<string>('SIDEBAR:DOCUMENTS_TYPES'),
                to: '/documents/types/list',
              },
            ],
            key: 'documents',
            label: i18next.t<string>('SIDEBAR:DOCUMENTS'),
            to: '/documents',
          },
          {
            icon: Groups2Outlined,
            key: 'persons',
            label: i18next.t<string>('SIDEBAR:PERSONS'),
            to: '/persons',
          },
        ]
      : []),
    ...(isSuperAdminRole(user)
      ? [
          {
            icon: PersonOutlined,
            items: [
              {
                icon: PersonSearchOutlined,
                key: 'users-list',
                label: i18next.t<string>('SIDEBAR:USERS_LIST'),
                to: '/users/list',
              },
              {
                icon: SecurityOutlined,
                key: 'users-roles',
                label: i18next.t<string>('SIDEBAR:USERS_ROLES'),
                to: '/users/roles/list',
              },
              {
                icon: SecurityOutlined,
                key: 'users-permissions',
                label: i18next.t<string>('SIDEBAR:USERS_PERMISSIONS'),
                to: '/users/permissions/list',
              },
            ],
            key: 'users',
            label: i18next.t<string>('SIDEBAR:USERS'),
            to: '/users',
          },
        ]
      : []),
    ...(isRabinatoRole(user)
      ? [
          {
            icon: PersonOutlined,
            key: 'users',
            label: i18next.t<string>('SIDEBAR:USERS'),
            to: '/users/list',
          },
        ]
      : []),
  ];

  return (
    <Sidebar
      isMenuOpened={isMenuOpened}
      isMobile={isMobile}
      items={items}
      onCloseMenu={onCloseMenu}
    />
  );
};

export default SidebarContainer;
