import React, { FunctionComponent } from 'react';
import { ArrowBackOutlined, EditOutlined } from '@mui/icons-material';
import i18next from 'i18next';

import { history } from 'helpers/history';

import BlocksGravesGrid from 'views/BlocksView/components/BlocksGravesGrid';

import {
  ViewContainer,
  ViewContent,
  TitleContainer,
  Title,
  TableContainer,
  CustomIconButton,
} from 'styles/view';

import { IBlocksShowProps } from './types';
import './i18n';

const BlocksShow: FunctionComponent<IBlocksShowProps> = (props: IBlocksShowProps) => {
  const { blockId, cementeryId, columns, graves, item, rows } = props;

  return (
    <ViewContainer auth data-testid="blocks-show">
      <ViewContent auth>
        <TitleContainer>
          <CustomIconButton onClick={history.goBack}>
            <ArrowBackOutlined />
          </CustomIconButton>
          <Title>{i18next.t<string>('BLOCKS_SHOW:TITLE')}</Title>
          <CustomIconButton
            onClick={() =>
              history.push(`/cementeries/${item?.cementeryId}/blocks/${item?.id}/edit`)
            }
          >
            <EditOutlined />
          </CustomIconButton>
        </TitleContainer>
        {item && (
          <>
            <TableContainer>
              <tbody>
                <tr>
                  <td>{i18next.t<string>('BLOCKS_TYPES_SHOW:ID')}</td>
                  <td>{item?.id}</td>
                </tr>
                <tr>
                  <td>{i18next.t<string>('BLOCKS_SHOW:NAME')}</td>
                  <td>{item?.name}</td>
                </tr>
              </tbody>
            </TableContainer>
            {!!Object.keys(graves).length && (
              <BlocksGravesGrid
                blockId={blockId}
                cementeryId={cementeryId}
                columns={columns}
                graves={graves}
                rows={rows}
              />
            )}
          </>
        )}
      </ViewContent>
    </ViewContainer>
  );
};

export default BlocksShow;
