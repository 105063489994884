import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { IAppState } from 'types/app.types';
import { IAuthState } from 'types/auth.types';
import { IBlocksState } from 'types/blocks.types';
import { ICementeriesState } from 'types/cementeries.types';
import { IDocumentsState } from 'types/documents.types';
import { IDocumentsTypesState } from 'types/documentsTypes.types';
import { IGravesState } from 'types/graves.types';
import { IPermissionsState } from 'types/permissions.types';
import { IPersonsState } from 'types/persons.types';
import { IRolesState } from 'types/roles.types';
import { IUsersState } from 'types/users.types';

export interface IRootState {
  app: IAppState;
  auth: IAuthState;
  blocks: IBlocksState;
  cementeries: ICementeriesState;
  documents: IDocumentsState;
  documentsTypes: IDocumentsTypesState;
  graves: IGravesState;
  permissions: IPermissionsState;
  persons: IPersonsState;
  roles: IRolesState;
  users: IUsersState;
}

const useTypedSelector: TypedUseSelectorHook<IRootState> = useSelector;

export default useTypedSelector;
