import { AxiosResponse } from 'axios';

import { get, post, put, remove } from 'services/api';
import { IBlocksFormData } from 'views/BlocksView/components/BlocksForm/types';

export const getAll = (
  cementeryId: number,
  limit: number,
  page: number,
  search: string,
  sortKey: string,
  sortType: string,
): Promise<AxiosResponse> =>
  get(
    `cementeries/${cementeryId}/blocks?limit=${limit}&page=${page}&search=${search}&sortKey=${sortKey}&sortType=${sortType}`,
  );

export const getOne = (cementeryId: number, id: string): Promise<AxiosResponse> =>
  get(`cementeries/${cementeryId}/blocks/${id}`);

export const save = (
  cementeryId: number,
  formData: IBlocksFormData,
  id: string,
): Promise<AxiosResponse> =>
  !id
    ? post(`cementeries/${cementeryId}/blocks`, formData)
    : put(`cementeries/${cementeryId}/blocks/${id}`, formData);

export const softDelete = (cementeryId: number, id: string): Promise<AxiosResponse> =>
  remove(`cementeries/${cementeryId}/blocks/${id}`);
