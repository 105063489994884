import React, { FunctionComponent } from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Divider, IconButton } from '@mui/material';
import { ArrowBackOutlined, DeleteOutlined } from '@mui/icons-material';
import i18next from 'i18next';

import { history } from 'helpers/history';
import { requiredValidation } from 'helpers/validations';

import Input from 'components/inputs/Input';
import BlocksGravesGrid from 'views/BlocksView/components/BlocksGravesGrid';

import { ViewContainer, ViewContent, TitleContainer, Title, CustomIconButton } from 'styles/view';
import {
  FormContent,
  FormButtonContainer,
  FormSubmitButton,
  FormRowContainer,
  FormAddCombinationButton,
} from 'styles/form';

import { IBlocksFormProps } from './types';
import './i18n';

const rowField = {
  columns: '',
};

const BlocksForm: FunctionComponent<IBlocksFormProps> = (props: IBlocksFormProps) => {
  const { blockId, cementeryId, columns, graves, initialValues, onSubmit, rows } = props;

  return (
    <ViewContainer auth data-testid="blocks-form">
      <ViewContent auth>
        <TitleContainer>
          <CustomIconButton onClick={history.goBack}>
            <ArrowBackOutlined />
          </CustomIconButton>
          <Title>{i18next.t<string>('BLOCKS_FORM:TITLE')}</Title>
          <div />
        </TitleContainer>
        <Form
          initialValues={initialValues}
          mutators={{ ...arrayMutators }}
          onSubmit={onSubmit}
          render={({
            form: {
              mutators: { push, remove },
            },
            handleSubmit,
            submitting,
            pristine,
            values,
          }) => (
            <FormContent onSubmit={handleSubmit}>
              <Field
                label={i18next.t<string>('BLOCKS_FORM:NAME')}
                name="name"
                render={Input}
                validate={requiredValidation}
              />
              {!!values.rows?.length && (
                <Field
                  label={i18next.t<string>('BLOCKS_FORM:PRICE')}
                  name="price"
                  render={Input}
                  type="number"
                  validate={requiredValidation}
                />
              )}
              {!!Object.keys(graves).length && (
                <BlocksGravesGrid
                  blockId={blockId}
                  cementeryId={cementeryId}
                  columns={columns}
                  graves={graves}
                  rows={rows}
                />
              )}
              <FieldArray name="rows">
                {({ fields }) =>
                  fields.map((name, i) => (
                    <div key={name}>
                      <Divider>
                        {`${i18next.t<string>('BLOCKS_FORM:ROW')} ${
                          (Number(Object.keys(graves)?.slice(-1)?.[0]) || 0) + i + 1
                        }`}
                        <IconButton onClick={() => remove('rows', i)}>
                          <DeleteOutlined />
                        </IconButton>
                      </Divider>
                      <FormRowContainer>
                        <Field
                          label={i18next.t<string>('BLOCKS_FORM:AMOUNT_OF_COLUMNS')}
                          name={`${name}.columns`}
                          render={Input}
                          type="number"
                          validate={requiredValidation}
                        />
                        <IconButton onClick={() => remove('rows', i)} style={{ height: 60 }}>
                          <DeleteOutlined />
                        </IconButton>
                      </FormRowContainer>
                    </div>
                  ))
                }
              </FieldArray>
              <FormAddCombinationButton onClick={() => push('rows', rowField)} variant="outlined">
                {i18next.t<string>('BLOCKS_FORM:ADD_ROW')}
              </FormAddCombinationButton>
              <FormButtonContainer>
                <FormSubmitButton disabled={pristine || submitting} type="submit">
                  {i18next.t<string>('BLOCKS_FORM:SUBMIT_BUTTON')}
                </FormSubmitButton>
              </FormButtonContainer>
            </FormContent>
          )}
        />
      </ViewContent>
    </ViewContainer>
  );
};

export default BlocksForm;
