import { AxiosResponse } from 'axios';

import { get, post, remove } from 'services/api';
import { IPersonsFormData } from 'views/PersonsView/components/PersonsForm/types';

export const getAll = (
  limit: number,
  page: number,
  search: string,
  sortKey: string,
  sortType: string,
  id?: number,
): Promise<AxiosResponse> =>
  get(
    `persons?limit=${limit}&page=${page}&search=${search}&sortKey=${sortKey}&sortType=${sortType}${
      id ? `&id=${id}` : ''
    }`,
  );

export const getForm = (): Promise<AxiosResponse> => get('persons/form');

export const getOne = (id: string): Promise<AxiosResponse> => get(`persons/${id}`);

export const save = (data: IPersonsFormData, id: string): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('first_name', data.firstName);
  formData.append('last_name', data.lastName);
  formData.append('last_name_mother', `${data.lastNameMother}`);
  formData.append('id_card', data.idCard);
  formData.append('email', `${data.email}`);
  formData.append('phone', `${data.phone}`);
  formData.append('civil_state', data.civilState);
  formData.append('birthdate', data.birthdate);
  formData.append('address', data.address);
  formData.append('tribe', data.tribe);
  formData.append('country', data.country);
  if (data.image) formData.append('image', data.image);
  formData.append('comments', `${data.comments}`);
  formData.append('alive', data.alive.toString());
  if (data.cementery) formData.append('cementery', data.cementery);
  if (data.relations) {
    const relations = data.relations.map(({ person, personState }) => ({
      person_state: personState,
      person_id: person.value,
    }));
    formData.append('relations', JSON.stringify(relations));
  }
  if (id) formData.append('_method', 'PUT');
  return post(`persons${!id ? '' : `/${id}`}`, formData, { transformRequest: () => formData });
};

export const softDelete = (id: string): Promise<AxiosResponse> => remove(`persons/${id}`);
